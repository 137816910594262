import * as React from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import Link from "../components/Link";
import { lighten } from "@material-ui/core/styles";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    padding: "3rem 0rem 3rem 0rem",
    color: theme.palette.primary.main,
  },
  btn: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  title: {
    fontWeight: "bold",
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <>
      <Navbar />
      <Container maxWidth="md">
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="h2" className={classes.title}>
                404
              </Typography>
              <Typography variant="h3">
                Leider ist etwas schief gelaufen.
              </Typography>
              <br />
              <Button
                variant="outlined"
                className={classes.btn}
                href="/"
                component={Link}
              >
                Zurück zur Startseite
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default NotFoundPage;
